<template>
  <Layout>
    <div class="container mt-4 py-5">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <h2 class="color-institucional">PREP</h2>

          <b-button v-b-toggle.collapse-1 variant="primary" class="w-100 d-block">
            Conoce el PREP
          </b-button>
          <b-collapse visible id="collapse-1" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/que-es"
              >
                ¿Qué es el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/como-opera"
              >
                ¿Cómo opera el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/difusores-oficiales"
              >
                Difusores oficiales del PREP 2024
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/conoce/linea-tiempo"
              >
                Línea del Tiempo PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-2 variant="primary" class="w-100 d-block">
            Navegación PREP
          </b-button>
          <b-collapse visible id="collapse-2" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/como-navego"
              >
                ¿Cómo navego en el PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/navegacion/elemento-apoyo"
              >
                Elementos de apoyo
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button v-b-toggle.collapse-3 variant="primary" class="w-100 d-block">
            Actas en PREP
          </b-button>
          <b-collapse visible id="collapse-3" class="mt-2">
            <b-list-group>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/que-es-acta"
                active
              >
                ¿Qué es el Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/consultar-imagen"
              >
                ¿Cómo consultar la imagen del Acta PREP?
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/estatus-digitalizacion-traslado"
              >
                Estatus, Digitalización y Traslado del Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/observaciones"
              >
                Observaciones en Acta PREP
              </b-list-group-item>
              <b-list-group-item
                to="/centro-de-ayuda/prep/actas/validez"
              >
                Validez del Acta PREP
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <br />

          <b-button
            to="/centro-de-ayuda/prep/base-de-datos/interpretacion"
            variant="primary"
            class="w-100 d-block"
          >
            Base de Datos
          </b-button>
        </div>
        <div class="col-12 col-lg-9">
          <b-breadcrumb>
            <b-breadcrumb-item to="/centro-de-ayuda" class="color-terciario">
              Inicio
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              Actas en PREP
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              <b>
                ¿Qué es el Acta PREP?
              </b>
            </b-breadcrumb-item>
          </b-breadcrumb>

          <h2 class="text-center color-institucional">
            ¿Qué es el Acta PREP?
          </h2>

          <div  class="row p-contenedor-30">
            <div  class="col-lg-12">
              <p  class="f-s-24"> Es la primera copia del Acta de Escrutinio y Cómputo, conocida por sus siglas como AEC, destinada para el PREP o en ausencia de ésta cualquier copia del Acta. </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 m-t-20">
            <div  class="col-lg-12 text-center">
              <h2  class="f-s-sub"> ¿Cuáles son los datos que contiene? </h2>
              <p  class="f-s-24"> El Acta PREP está conformada por los siguientes apartados. </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20">
            <div  class="col-lg-6">
              <div  class="row">
                <div  class="col-lg-2 col-2 imagenes-50 text-right sn-p-r"><img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-1.svg" alt=""></div>
                <div  class="col-lg-10 col-10">
                  <p  class="f-s-24 f-w-500"> Primer apartado </p>
                </div>
              </div>
              <div  class="row m-t-20">
                <div  class="col-lg-12 list-stilo sn-m-b">
                  <p  class="f-s-24 f-w-500"> Espacio para colocar la etiqueta del Código QR:​ </p>
                  <ul  class="f-s-24">
                    <li > El Código QR es una imagen bidimensional que almacena, de forma codificada, la información que permite identificar cada Acta PREP a través de medios electrónicos. </li>
                  </ul>
                  <p  class="f-s-24 f-w-500"> Espacio para colocar los siguientes datos, tanto en letra como en número: </p>
                  <ul  class="f-s-24">
                    <li >
                      Identificación del Acta 
                      <ul >
                        <li > Entidad Federativa </li>
                        <li > Distrito Electoral. </li>
                        <li > Sección. </li>
                        <li > Municipio o Alcaldía. </li>
                        <li > Tipo de Casilla. </li>
                        <li > Número de Casilla. </li>
                      </ul>
                    </li>
                    <li > Boletas sobrantes. </li>
                    <li > Personas que votaron. </li>
                    <li > Representantes de Partidos Políticos y de Candidaturas Independientes acreditados ante casilla que votaron. </li>
                    <li > Total de personas que votaron y representantes. </li>
                  </ul>
                </div>
              </div>
            </div>
            <div  class="col-lg-6 imagenes-70 text-center align-self-center"><img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-1.svg" alt=""></div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20">
            <div  class="col-lg-6">
              <div  class="row rsp-pt60">
                <div  class="col-lg-2 col-2 imagenes-50 text-right sn-p-r"><img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-2.svg" alt=""></div>
                <div  class="col-lg-10 col-10">
                  <p  class="f-s-24 f-w-500"> Segundo apartado </p>
                </div>
              </div>
              <div  class="row m-t-20 rsp-pt60">
                <div  class="col-lg-12 list-stilo sn-m-b">
                  <p  class="f-s-24 f-w-500"> Espacio para colocar los siguientes datos, tanto en letra como en número </p>
                  <ul  class="f-s-24">
                    <li >
                      Resultados de la votación: 
                      <ul >
                        <li > Votos por Partidos Políticos, Coaliciones, Candidaturas no registradas y Votos nulos.​ </li>
                      </ul>
                    </li>
                    <li > Total de votos de la elección sacados de todas las urnas.​ </li>
                    <li > Comparativo del total de personas que votaron y el total de votos de la elección sacados de todas las urnas. </li>
                    <li > Comparativo del total de votos de la elección sacados de todas las urnas y el total de resultados de la votación. ​ </li>
                  </ul>
                </div>
              </div>
            </div>
            <div  class="col-lg-6 imagenes-70 text-center align-self-center"><img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-2.svg" alt=""></div>
          </div>
          <div  class="row p-contenedor-30 list-stilo m-t-20 m-b-50">
            <div  class="col-lg-6">
              <div  class="row">
                <div  class="col-lg-2 col-2 imagenes-50 text-right sn-p-r"><img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/c-rosa-3.svg" alt=""></div>
                <div  class="col-lg-10 col-10">
                  <p  class="f-s-24 f-w-500"> Tercer apartado </p>
                </div>
              </div>
              <div  class="row m-t-20">
                <div  class="col-lg-12 list-stilo sn-m-b">
                  <p  class="f-s-24 f-w-500"> Espacio para colocar los siguientes datos:​ </p>
                  <ul  class="f-s-24">
                    <li > Incidentes durante el escrutinio y cómputo. </li>
                    <li > Nombres y firmas del funcionariado de la Mesa Directiva de Casilla, así como las representaciones de Partidos Políticos y Candidaturas Independientes.​ </li>
                    <li >
                      Escritos de protesta: 
                      <ul >
                        <li > Registro de Funcionarios de casilla. </li>
                        <li > Registro de Representante de Partido Político. </li>
                      </ul>
                    </li>
                    <li > Instrucciones para el procesamiento del Acta PREP.​ </li>
                  </ul>
                </div>
              </div>
            </div>
            <div  class="col-lg-6 imagenes-70 text-center align-self-center"><img class="img-fluid mx-auto d-block" src="https://ayuda.ine.mx/2024/informate/assets/img/prep/acta-3.svg" alt=""></div>
          </div>
          <div  class="row p-contenedor-30 m-b-50">
            <div  class="col-lg-12 text-center f-s-16">
              <p  class="f-w-500"> Referencias: </p>
              <p  class="referencias"> · Anexo 13, Capítulo único, Numeral 1, Fracción I y numeral 26. </p>
            </div>
          </div>
          <div  class="row p-contenedor-30 m-t-50 m-b-50">
            <div  class="col-lg-12 text-center">
                <a
                  href="https://ayuda.ine.mx/2024/informate/assets/docs/Prep/Actas_en_PREP_Infografias/Que_es_Acta_PREP.pdf"
                  target="_blank"
                  class="btn btn-primary mx-auto d-block"
                >
                  Descarga Infografía
                </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/CentroDeAyuda/Layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
  margin-left: 30px;
}

.list-group-item {
  z-index: 0;
}

.grid-tiempo {
  display: grid;
  align-items: center;
  grid-template-columns: 25% 1fr;
  color: white;

  > img {
    max-width: 45px;
  }

  > p {
    margin-bottom: 0;
  }

  .grid-interno {
    display: grid;
    align-items: center;
    gap: 45px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &:nth-child(odd) {
    background-color: #766CAE;

    .detalle-block {
      background-color: #5C5688;
    }

    .descripcion-block {
      background-color: #4C4871;
    }
  }

  &:nth-child(even) {
    background-color: #E50077;

    .detalle-block {
      background-color: #962464;
    }

    .descripcion-block {
      background-color: #6B1046;
    }
  }
}

.list-group-item.active {
  background-color: #D2127E;
  border-color: transparent;
}

.row {
  padding-bottom: 15px;
  padding-top: 15px;
}
</style>
